import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { FiMapPin, FiGlobe, FiTwitter, FiLinkedin } from "react-icons/fi"
import { FaTiktok } from "react-icons/fa"
import ContactFormNetlify from "./contactformnetlify"
import { Link } from "gatsby"
const infoList = [
  {
    id: "1",
    icon: <FiMapPin />,
    label: "Situation géographique :",
    link: "Crozet, Pays de Gex, France",
  },
  {
    id: "2",
    icon: <FiGlobe />,
    label: "Site Web :",
    link: "seoalternative.fr",
  },
  {
    id: "3",
    icon: <FiTwitter />,
    label: "Twitter:",
    link: "@marie_luce",
    labelvalue: "https://twitter.com/marie_luce",
  },
  {
    id: "4",
    icon: <FiLinkedin />,
    label: "LinkedIn:",
    link: "Marie-Luce Beraudo",
    labelvalue: "https://www.linkedin.com/in/marieluceberaudo/",
  },
  {
    id: "5",
    icon: <FaTiktok />,
    label: "TikTok:",
    link: "SEO Alternative",
    labelvalue: "https://www.tiktok.com/@seoalternative",
  },
]

const Contact = () => {
  const contactData = useStaticQuery(graphql`
    query contactDataQuery {
      contactJson {
        title
        subtitle
      }
     
    }
  `)
  const Title = contactData.contactJson.title
  const Description = contactData.contactJson.subtitle

  return (
    <div className="rn-contact-area rn-section-gap bg-color-white" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title mb--30">
              <span className="subtitle">{Description}</span>
              <h2
                className="title"
                dangerouslySetInnerHTML={{ __html: Title }}
              ></h2>
              <p>
                N'hésitez pas à me contacter pour toute prestation en <Link to="/seo-services"><strong>référencement naturel/SEO</strong></Link> ou <Link to="/dev-services"><strong>développement Web</strong></Link>.
              </p>
              <p>
                Basée dans le pays de Gex près de Genève, je travaille avec des
                clients dans toute la France, en Suisse et à l'étranger.
              </p>
              <p className="description mt_sm--30 mt_md--30">
                Pour me contacter, il vous suffit de remplir le formulaire
                ci-dessous. <br></br>Je vous répondrai dans les 48 heures.{" "}
                <br></br>Vous pouvez également me contacter par téléphone au{" "}
                <a href="tel:+33786463681">+33(0)7.86.46.36.81</a>
              </p>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Start Contact Form  */}
          <div className="col-lg-6 col-12 mt--30">
            <ContactFormNetlify></ContactFormNetlify>
          </div>
          {/* End Contact Form  */}

          <div className="col-lg-6 col-12 mt--30">
            <div className="contact-info-list-wrapper">
              {infoList.map((value, index) => (
                <div className="list" key={index}>
                  <div className="icon">{value.icon}</div>
                  <span className="label">{value.label}</span>
                  <a
                    className="link"
                    href={value.labelvalue}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {value.link}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
